import React, { useState, useEffect } from 'react';
import { Typography, Container, Box, Grid, Paper, TextField, IconButton, Link } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import InviteFriends from './components/InviteFriends';
import UpdateContactInfo from './components/UpdateContactInfo';
import UnderstandingCredits from './components/UnderstandingCredits';
import CustomTShirtSelection from './components/CustomTShirtSelection';
import MyReferrals from './components/MyReferrals';
import NavigationBar from './NavigationBar';
import BillingManagement from './components/BillingManagement';
import NextBillingDate from './components/NextBillingDate';
import CreditBalance from './components/CreditBalance';
import Transactions from './components/Transactions';
import MySports from './components/MySports'; // Import MySports component
import { useAuth } from './context/AuthContext';
import AppStoreLinks from './components/AppStoreLinks';  // Import AppStoreLinks

const Root = styled('div')({
    position: 'relative',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1c1c1c',
    padding: '2rem',
});

const StyledContainer = styled(Container)({
    textAlign: 'center',
    padding: '2rem',
    zIndex: 2,
    marginTop: '4rem',
    '@media (max-width: 600px)': {
        padding: '1rem',
    },
});

const InfoBox = styled(Paper)({
    backgroundColor: '#202020',
    padding: '1.5rem',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    textAlign: 'left',
    color: '#fff',
    marginBottom: '1rem',
});

const Footer = styled(Box)({
    marginTop: '2rem',
    padding: '1rem',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    flexWrap: 'wrap',
    '@media (max-width: 600px)': {
        flexDirection: 'column',
        alignItems: 'center',
    },
    '& a': {
        color: '#12c099',
        textDecoration: 'none',
        fontSize: '0.8rem',
        '@media (max-width: 600px)': {
            fontSize: '0.7rem',
        },
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

const Dashboard = () => {
    const { logout } = useAuth();
    const [user, setUser] = useState(null);
    const [credits, setCredits] = useState(0);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [country, setCountry] = useState('');
    const [autoRebill, setAutoRebill] = useState(false);
    const [referralCode, setReferralCode] = useState('');
    const [subscriptionPaused, setSubscriptionPaused] = useState(false);
    const [subscriptionStarted, setSubscriptionStarted] = useState(false);
    const [marsClubChecked, setMarsClubChecked] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userSports, setUserSports] = useState([]); // State to track user sports

    useEffect(() => {
        const fetchUserDetails = async (firebaseUid) => {
            try {
                const response = await axios.get(`https://api.academy.marsreel.com/api/users/details/${firebaseUid}`);
                const userData = response.data.user;

                setUser(userData);
                setIsLoggedIn(true);
                setUserSports(userData.sports || []); // Set user sports based on their details
                setCredits(userData.accountBalance);
                setEmail(userData.email);
                setPhone(userData.phone);
                setStreet(userData.address ? userData.address.street : '');
                setCity(userData.address ? userData.address.city : '');
                setState(userData.address ? userData.address.state : '');
                setZipCode(userData.address ? userData.address.zipCode : '');
                setCountry(userData.address ? userData.address.country : '');
                setAutoRebill(userData.autoRebill || false);
                setReferralCode(userData.referralCode);

                const recurringPlan = userData.planDetails?.find(plan => plan.isRecurring);

                if (recurringPlan) {
                    setSubscriptionStarted(true);
                    setSubscriptionPaused(recurringPlan.isPaused);
                } else {
                    setSubscriptionStarted(false);
                    setSubscriptionPaused(false);
                }
            } catch (error) {
                console.error('Failed to fetch user details:', error);
            }
        };

        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchUserDetails(user.uid);
            } else {
                setIsLoggedIn(false);
            }
        });
    }, []);

    const handleLogout = async () => {
        try {
            await logout();
            setIsLoggedIn(false);
            window.location.href = '/login';
        } catch (error) {
            console.error('Failed to log out', error);
        }
    };

    const handleCopyReferralCode = () => {
        navigator.clipboard.writeText(referralCode)
            .then(() => alert('Referral code copied to clipboard'))
            .catch(err => console.error('Failed to copy referral code: ', err));
    };

    return (
        <>
            <NavigationBar isLoggedIn={isLoggedIn} handleLogout={handleLogout} showDashboardLink={true} />

            <Root>
                <StyledContainer maxWidth="lg">
                    <Typography variant="h4" gutterBottom>
                        Hello, {user ? user.firstName : ''}
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <UnderstandingCredits />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CreditBalance credits={credits} setCredits={setCredits} user={user} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <NextBillingDate user={user} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InfoBox>
                                <UpdateContactInfo
                                    email={email}
                                    setEmail={setEmail}
                                    phone={phone}
                                    setPhone={setPhone}
                                    street={street}
                                    setStreet={setStreet}
                                    city={city}
                                    setCity={setCity}
                                    state={state}
                                    setState={setState}
                                    zipCode={zipCode}
                                    setZipCode={setZipCode}
                                    country={country}
                                    setCountry={setCountry}
                                />
                            </InfoBox>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <BillingManagement
                                user={user}
                                setUser={setUser}
                                subscriptionStarted={subscriptionStarted}
                                setSubscriptionStarted={setSubscriptionStarted}
                                subscriptionPaused={subscriptionPaused}
                                setSubscriptionPaused={setSubscriptionPaused}
                                marsClubChecked={marsClubChecked}
                                setMarsClubChecked={setMarsClubChecked}
                                autoRebill={autoRebill}
                                setAutoRebill={setAutoRebill}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InfoBox>
                                <MySports userSports={userSports} setUserSports={setUserSports} />
                            </InfoBox>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <CustomTShirtSelection />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InviteFriends
                                firstName={user ? user.firstName : ''}
                                lastName={user ? user.lastName : ''}
                                referralCode={referralCode}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InfoBox>
                                <Typography variant="h6">My Referral Code</Typography>
                                <Box display="flex" alignItems="center" marginTop="0.5rem">
                                    <TextField
                                        value={referralCode}
                                        fullWidth
                                        variant="filled"
                                        InputProps={{
                                            readOnly: true,
                                            style: { color: '#fff' },
                                        }}
                                    />
                                    <IconButton onClick={handleCopyReferralCode} style={{ marginLeft: '0.5rem', color: '#12c099' }}>
                                        <FileCopyIcon />
                                    </IconButton>
                                </Box>
                            </InfoBox>
                        </Grid>
                        <Grid item xs={12}>
                            <MyReferrals />
                        </Grid>
                        <Grid item xs={12}>
                            <Transactions firebaseUid={user ? user.firebaseUid : null} />
                        </Grid>
                    </Grid>

                    {/* App Store Links Section */}
                    <AppStoreLinks /> {/* Added here */}

                </StyledContainer>

                <Footer>
                    <Link href="/faq">FAQ</Link>
                    <Link href="/help-center">Help Center</Link>
                    <Link href="/terms-of-use">Terms of Use</Link>
                    <Link href="/privacy">Privacy</Link>
                    <Link href="/company-policy">Company Policy</Link>
                    <Link href="/engineering">Engineering & Data Science</Link>
                </Footer>
            </Root>
        </>
    );
};

export default Dashboard;
